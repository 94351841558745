/* commonStyles.css */

.apb-logo {
  width: 40%;
  border-radius: 10px;
  object-fit: contain;
  /* margin-left: 20px; */
}
/* Adjust size for xs and sm screens */
@media (max-width: 576px) {
  .apb-logo {
    width: 130px;
  }
}

.activate-plan-img {
  width: 100%;
  display: flex;
  text-align: start;
  justify-content: flex-end;
}

.input-label-heading {
  font-size: 20px;
  font-weight: "bold";
  font-family: "Poppins", serif;
}

.common-label {
  width: 100%;
  text-align: start;
  font-weight: 400;
  font-family: "Poppins", serif;
}

.common-input {
  width: 100%;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", serif;
}

.common-feedback {
  width: 100%;
  text-align: start;
  background-color: #fff;
}

.drop-down-input {
  width: 100%;
  border-width: 1;
  display: flex;
  align-items: center;
  border-color: lightgrey;
  background-color: #fff;
  justify-content: space-between;
  height: 50px;
  font-size: 14px;
  font-weight: 400;
  font-family: "Poppins", serif;
}

.drop-down-menu {
  border-width: 1;
  border-color: #ced4da;
  background-color: #f8f9fa;
}

.intructions-point-icon {
  width: 3%;
  margin-right: 10px;
  margin-top: 5px;
}

.video-main-container {
  color: white;
  background-size: cover;
  background-position: center;
  background-image: url("../../../assets//Apb//BallonFly.jpg");
  /* height: 70vh; Uncomment if you want to set the height */
}

.video-sub-main-container {
  height: auto;
  color: white;
  background-size: cover;
  background-position: center;
  background-image: url("../../../assets//Apb//BallonFly.jpg");
}

.font-size-vw {
  font-size: 2vw;
  text-align: left;
  text-decoration: underline;
}
@media (max-width: 768px) {
  /* For sm and xs screens */
  .font-size-vw {
    font-size: 4vw;
  }
}

.upload-guide-video {
  width: 100%;
  height: 305px;
  border-radius: 10px;
  border: 2px solid white;
}

.upload-card-container {
  width: 100%;
  height: 305px;
  padding: 10px;
  border-radius: 10px;
  background-color: #f8f9fa;
  border: 2px dashed #ced4da;
}
