/* Custom styling for the dropdown toggle button */
.custom-dropdown .dropdown-toggle {
  background-color: #f5f7fa; /* Custom background color */
  border: 1px solid #e0e0e0; /* Custom border color */
  border-radius: 10px; /* Custom border radius */
  color: #333; /* Custom text color */
  padding: 10px 20px; /* Custom padding */
  width: 100%; /* Full width */
  text-align: left; /* Align text to the left */
}

.custom-dropdown .dropdown-toggle:focus {
  box-shadow: none; /* Remove focus outline */
  background-color: #e0e0e0; /* Change background color on focus */
}

/* Remove the default arrow and customize it */
.custom-dropdown .dropdown-toggle::after {
  display: none; /* Hide the default arrow */
}

/* Custom dropdown menu styling */
.custom-dropdown .dropdown-menu {
  border-radius: 10px; /* Custom border radius */
  border: 1px solid #e0e0e0; /* Custom border color */
  box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1); /* Custom shadow */
  width: 100%; /* Full width dropdown */
  margin-top: 5px; /* Custom margin */
}

/* Custom dropdown item styling */
.custom-dropdown .dropdown-menu .dropdown-item {
  padding: 10px 20px; /* Custom padding */
  color: #333; /* Custom text color */
}

.custom-dropdown .dropdown-menu .dropdown-item:hover {
  background-color: #f5f7fa; /* Custom hover background */
  color: #000; /* Custom hover text color */
}

/* Custom styling for disabled items */
.custom-dropdown .dropdown-menu .dropdown-item:disabled {
  color: #999; /* Custom disabled text color */
  background-color: transparent; /* Transparent background */
}

/* Custom styling for the dropdown icon */
.custom-dropdown .dropdown-toggle-icon {
  font-size: 14px;
  color: #b0b0b0;
  margin-left: 10px;
  vertical-align: middle;
}

/* *********** Wrapper to center the image container *********** */
.image-container-wrapper {
  height: 100vh; /* Full viewport height */
  width: 100%; /* Full width */
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #f8f9fa;
}

/* Styling for the image container */
.image-container {
  max-width: 80%; /* Image container width on larger screens */
  border: 2px solid #1e6cfd; /* Optional border for aesthetics */
  border-radius: 10px;
  overflow: hidden; /* Ensures the image fits within the container */
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2); /* Subtle shadow effect */
}

/* Responsive design */
@media (max-width: 768px) {
  .image-container {
    max-width: 90%; /* Adjust container width on medium screens */
  }
}

@media (max-width: 576px) {
  .image-container {
    max-width: 100%; /* Full width for small screens */
  }
}

/* *********** Instructions Styling *********** */
.instructions-container {
  /* background-color: #f8f9fa;  */
}

.instruction-item {
  margin-bottom: 1rem; /* Space between items */
}

.instruction-item p {
  font-size: 16px; /* Standard text size */
  color: #212529; /* Dark text color */
}

.instruction-item .text-muted {
  font-size: 14px; /* Smaller size for translations */
}
